export const DEFAULT_GRID_COL = 4;
export const PG_GRID_CHOICE = "pgGridChoice";
export const DEFAULT_GRID_COL_CONCIERGE = 4;
export const LAST_SORTMENU = "lastSortMenu";
export const SELECTED_BRAND_COOKIE = "selected_brand";
export const DESIRED_CONCEPT_TAB_ORDER = [
  "RH",
  "Outdoor",
  "Baby & Child",
  "Teen"
];
export const FEATURE_SEARCH_ALL_BRANDS = "FEATURE_SEARCH_ALL_BRANDS";
export const FEATURE_CONCEPT_TABS = "FEATURE_CONCEPT_TABS";
export const SITES = { "RH BABY & CHILD": "BC", "RH TEEN": "TN" };
